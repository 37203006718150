<template>
    <div class="container-fluid">
         <b-row>
            <b-col lg="12">
               Here Add Your HTML Content.....
            </b-col>
         </b-row>
      </div>
</template>
<script>
export default {
    name:'BlankPage'
}
</script>